.app-promotion {
  background: white;
  border-bottom: solid 1px rgba(0,0,0,0.1);
  padding: 10px;
}

.app-promotion-open-button {
  background: #017afe;
  color: white;
  border-radius: 25px;
  border: none;
  float: right;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: 700;
  font-size: 14px;
}

.app-promotion-logo {
  height: 26px;
}