.order-item-text {
	font-weight: 700;
	font-size: 18px;
}

.order-item-option {
	margin-top: -5px;
}

.order-item-option-text {
	margin-left: 15px;
	font-size: 16px;
	font-weight: 700;
	margin-bottom: 0px;
	margin-top: 0px;
	color: var(--secondary-text-color);
}

.order-item-option-value-text {
	margin-left: 15px;
	font-size: 16px;
	margin-top: 0px;
	color: var(--secondary-text-color-light);
}