html {
  --logo-color: #f04a25;
}

.app {
  padding-left: 2.5%;
  padding-right: 2.5%;
  width: 95%;
  max-width: 700px;
  margin: 0 auto;
  /*border: solid 1px rgba(0,0,0,0.1);*/
  font-size: 16px;
  font-family: 'Anek Telugu', sans-serif;
}

.divider {
  border: solid 0.5px rgba(0,0,0,0.2);
  margin-top: 50px;
  margin-bottom: 50px;
}