html {
	--title: black;
	--title-light: #726F6E;
	--secondary-text-color: #525F6A;
	--secondary-text-color-light: rgba(82, 95, 106, 0.7);
}

.order-status-restaurant-name {
	font-weight: 700;
	font-size: 22px;
	color: var(--secondary-text-color-light);
}

.order-status-phrase {
	font-weight: 700;
	font-size: 26px;
}

.order-status-eta-phrase {
	
}

.order-status-eta-range {
	font-weight: 700;
	color: var(--logo-color);
}

.order-status-time-of-arrival {
	float: left;
}

.order-status-eta {
	float: right;
}

.order-status-bar-labels {
	margin-bottom: 50px;
	color: var(--secondary-text-color-light);
	font-weight: 700;
}